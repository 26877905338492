<template>
  <v-toolbar flat class="mb-4">
    <v-toolbar-title>
      {{ $t("report.title") }}
      <span></span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            color="accent"
            fab
            small
            router
            to="/report"
            v-on="on"
          >
            <v-icon dark>mdi-file-export</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("report.title") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            color="primary"
            fab
            small
            v-on="on"
            @click="dialog = true"
          >
            <v-icon dark>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("report.exportDownload") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            router
            to="/report/create"
            v-on="on"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.create") }}</span>
      </v-tooltip>

      <v-dialog v-model="dialog" max-width="450">
        <ReportExport v-on:download-complete="dialog = false" />
      </v-dialog>
    </div>
  </v-toolbar>
</template>

<script>
import ReportExport from "@/components/report/ReportExport";
export default {
  name: "ReportToolbar",
  data() {
    return { dialog: false };
  },

  components: { ReportExport },
};
</script>
